$tagline-color:				#9fa6b1;
$banner-bg-color:			#cecbc4;
$banner-font-color-1:		#0a3380;
$banner-font-color-2:		#54ced4;
$banner-font-size:			72px;
$banner-font-weight:		700;
$banner-height:				820px;
$banner-celebration-image:		url('/assets/img/banner-christie-gray.jpg');
$banner-college-station-image:	url('/assets/img/banner-ashley-gray.jpg');
$banner-spine-left-offset:	-125px;
$blue:						#3b4b68;
$copyright-font-color:		#aeb6c2;
$features-card-height:		370px;
$features-card-offset:		-30px;
$features-card-width:		370px;
$features-bg-height:		200px;
$features-height:			440px;
$gradient-bg:				linear-gradient(90deg, #4fc9d8 0%, #58a8ec 100%);
$landing-page-celebration-image:		url('/assets/img/celebration-water-tower.png');
$landing-page-college-station-image:	url('/assets/img/college-station-water-tower.png');
$landing-page-height:		700px;
$light-blue:				#2ea6f7;
$white:						#fff;
$why-me-bg-color:			#cecbc4;
$why-me-height:				714px;
$why-me-celebration-image:		url('/assets/img/why-me-christie-gray.png');
$why-me-college-station-image:	url('/assets/img/why-me-ashley-gray.png');

.btn {
	color:					$white;
	font-weight:			bold;
	text-decoration:		none;
}

.tagline {
	color:					$tagline-color;
}

.banner {
	text-align:				center;
}

.contact {
	h4 a {
		text-decoration:	none;
	}
}

.content {
	color:					#6c757d;

	h1, h2, h3, h4, h5 {
		margin-bottom:		1em;
	}
}

.copyright {
	color:					$copyright-font-color;
	text-align:				right;
}

.features {
	display:				none !important;
	height:					$features-height;
	position:				relative;

	.btn {
		color:				$blue;
		height:				72px;
		padding:			0 30px;
	}

	.card {
		height:				$features-card-height;
		margin-top:			$features-card-offset;
		width:				$features-card-width;
		text-align:			center;
	}

	.features-button {
		margin-top:			$features-card-offset;
	}

	.gradient {
		height:				$features-bg-height;
	}
}

.features-mobile {
	margin-top:				1em;
	max-width:				75%;

	.btn {
		color:				$blue;
	}

	.card {
		border:				0px;
	}
}

.gradient {
	background-image:		$gradient-bg;
}

.home-banner {
	background-color:		$banner-bg-color;
	background-position:	right center;
	background-repeat:		no-repeat;
	display:				none !important;
	overflow:				hidden;
	position:				relative;

	.btn {
		position:			relative;
		height:				96px;
		padding:			0 30px;
	}

	.row {
		height:				$banner-height;
	}

	.welcome {
		color:				$banner-font-color-1;
		font-size:			$banner-font-size;
		font-weight:		$banner-font-weight;
		position:			relative;
	}

	.welcome-color-2 {
		color:				$banner-font-color-2;
	}
}

.home-banner-celebration {
	background-image:		$banner-celebration-image;
}

.home-banner-college-station {
	background-image:		$banner-college-station-image;
}

.home-description {
	.description {
		color:					$white;
		font-size:				20px;
		font-weight:			400;
		text-align:				center;
	}

	.signature {
		height:					52px;
	}
}

.home-description-college-station {
	.signature {
		height:					64px;
	}
}

.landing-page {
	.landing-page-celebration {
		background-image:		$landing-page-celebration-image;
		background-position:	center;
		background-repeat:		no-repeat;
		height:					$landing-page-height;
		padding-top:			calc($landing-page-height / 2);
	}

	.landing-page-college-station {
		background-image:		$landing-page-college-station-image;
		background-position:	center;
		background-repeat:		no-repeat;
		height:					$landing-page-height;
		padding-top:			calc($landing-page-height / 2);
	}

	.btn {
		height:				96px;
		padding:			0 30px;
	}

	.row {
		height:				$landing-page-height;
	}
}

.nav-link.active {
	border-bottom:			3px solid $blue;
}

.spine {
	left: 					$banner-spine-left-offset;
	position: 				absolute;
	top: 					0;

}

.tagline {
	display:				none !important;
}

.team-member {
	p {
		white-space: pre-wrap;
	}
}

.why-me {
	background-color:		$why-me-bg-color;
	background-position:	left center;
	background-repeat:		no-repeat;
	overflow:				hidden;
	position:				relative;
}

#pricingTabsContent {
	.card {
		height:				12rem;
		width:				20rem;
		text-align:			center;

		.card-text {
			color:			$light-blue;
			font-size:		1rem;
			margin-top:		15px;
			vertical-align:	bottom;

			.dollar-sign {
				display:		inline-block;
				font-size:		1.5rem;
				line-height:	3.5rem;
				padding-right:	.5rem;
				vertical-align:	top;
			}

			.price {
				font-size:	5rem;
			}
		}

		.card-title {
			color:			$blue;
			font-weight:	bold;
		}



	}
}

main {
	h1, h2, h3, h4, h5 {
		color:				$blue;
	}
}

@media (min-width: 606px) {		// more than small breakpoint + some extra
	.home-banner {
		display:			block !important;
	}

	.tagline {
		display:			block !important;
	}
}

@media (max-width: 768px) {		// less than medium breakpoint
	.landing-page {
		.row {
			height:			calc($landing-page-height * 2);
		}
	}
}

@media (min-width: 992px) {		// more than large breakpoint
	.why-me {
		.row {
			height:			$why-me-height;
		}
	}

	.why-me-celebration {
		background-image:	$why-me-celebration-image;
	}

	.why-me-college-station {
		background-image:	$why-me-college-station-image;
	}
}

@media (min-width: 1200px) {	// more than x-large breakpoint
	.features {
		display:			block !important;
	}

	.features-mobile {
		display:			none;
	}
}